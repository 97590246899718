import * as React from "react"
import Layout from "../components/layout";
import IceRinkLive from "../components/elements/iceRinkLive";
import {Helmet} from "react-helmet";
import {useState} from "react";
import {graphql} from "gatsby";
import {renderRichText} from "gatsby-source-contentful/rich-text";
import Footer from "../components/footer";
import Header from "../components/header";

const IceRinkPage = ({data}) => {

    const partners = data.allContentfulPartners.nodes;
    const teams = data.allContentfulTeam.nodes
    const pages = data.allContentfulPages.nodes

    const [activeSlide,setActiveSlide] = useState(0);
    const milestones = data.allContentfulIcerinkMilestones.nodes;
    const content = data.contentfulContents;

    function changeSlide(direction){
        if(direction === 'next'){
            if(activeSlide < milestones.length-1){
                setActiveSlide(activeSlide+1)
            }
        }
        if(direction === 'prev'){
            if(activeSlide > 0){
                setActiveSlide(activeSlide-1)
            }
        }
    }

    return (
        <Layout>
            <Helmet>
                <title>Jégcsarnok - Zempléni Hiúzok</title>
            </Helmet>
            <Header pages={pages} teams={teams}/>
            <main>
                <section className="section section-md bg-gray-100">
                    <div className="container">
                        <div className="row">
                            <div className="col-12">
                                <article className="heading-component">
                                    <div className="heading-component-inner">
                                        <h5 className="heading-component-title">Jégcsarnok</h5>
                                    </div>
                                </article>

                                <div className="blog-post">
                                    <h3 className="blog-post-title">{content.title}</h3>

                                    <div className="blog-post-author-quote">
                                        {renderRichText(content.text)}
                                    </div>
                                </div>


                                <div className="tabs-custom tabs-horizontal tabs-corporate tabs-corporate-boxed"
                                     id="tabs-1" data-nav="true">
                                    <div className="nav-wrap">
                                        <button className="button button-nav button-prev"  onClick={()=>changeSlide('prev')}><span className="icon mdi mdi-chevron-left"/></button>
                                        <ul className="nav nav-tabs">
                                            {milestones.map(function(elem,index){
                                                return <li className="nav-item" role="presentation" key={index} onClick={()=>setActiveSlide(index)}>
                                                    <div className={`nav-link ${index === activeSlide && 'active'}`}><span>{elem.year}</span></div>
                                                </li>
                                            })}
                                        </ul>
                                        <button className="button button-nav button-next" onClick={()=>changeSlide('next')}><span className="icon mdi mdi-chevron-right"/></button>
                                    </div>
                                    <div className="tab-content">
                                        {milestones.map(function(elem,index) {
                                            return <div key={index} className={`tab-pane fade ${index === activeSlide && 'active show'}`} id="tabs-1-1">
                                                <div className="tab-content-main">
                                                    <div className="row row-30">
                                                        <div className="col-lg-6">
                                                            <h2>{elem.year}</h2>
                                                            <h4>{elem.title}</h4>
                                                            {renderRichText(elem.text)}
                                                        </div>

                                                        <div className={'col-lg-6'}>
                                                            <img src={elem.image.url} className={'float-end'} alt={elem.title}/>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        })}
                                    </div>
                                </div>
                            </div>
                        </div>

                    </div>
                </section>
            </main>
            <Footer partners={partners}/>
        </Layout>
    )
}

export default IceRinkPage


export const query = graphql`
  query getContent {
  allContentfulIcerinkMilestones(sort: {fields: year, order: ASC}) {
    nodes {
      image {
        url
      }
      text {
        raw
      }
      title
      year
    }
  }
  allContentfulPartners {
    nodes {
      title
      logo {
        url
      }
    }
  }
  contentfulContents(id: {eq: "2497b6fd-05ec-5427-b604-f4b5a454b4ea"}){
      title
      text {
        raw
      }
      id
  }
  allContentfulPages {
    nodes {
      text {
        raw
      }
      title
      slug
    }
  }
  allContentfulTeam(sort: {fields: title, order: ASC}) {
    nodes {
      title
      label
    }
  }
}
`

